<template>
  <div>
    <landingCover
      :coverImage="coverImage"
      logo=true
      :title="$t('veterinary.headerTitle')"
      :subtitle="$t('veterinary.headerSubtitle')"
      routerName="registerVeterinarian"
      :btnTitle="$t('veterinary.headerBtn')"
      :otherLogo=false
      routerAboutName="aboutVeterinarian"
    />
    <landing-details
      :img="landingDetailsImage"
      :details="$t('veterinary.headerDetails')"
    />
    <landing-features :features="features" :header="$t('veterinary.ourServices')" />
    <landing-how :items="items" />
    <landing-footer
      routerName="registerVeterinarian"
      :title="$t('veterinary.veterinaryServices')"
      :desc="$t('veterinary.veterinaryServicesDesc')"
      :btnTitle="$t('veterinary.headerBtn')"
    />
  </div>
  </template>
<script>
import { core } from '@/config/pluginInit'
import landingCover from '../../mainComponentsLandingPage/landingCover.vue'
import landingDetails from '../../mainComponentsLandingPage/landingDetails.vue'
import landingFeatures from '../../mainComponentsLandingPage/landingFeatures.vue'
import landingHow from '../../mainComponentsLandingPage/landingHow.vue'
import landingFooter from '../../mainComponentsLandingPage/landingFooter.vue'

export default {
  name: 'veterinarian',
  components: {
    landingCover,
    landingDetails,
    landingFeatures,
    landingHow,
    landingFooter
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      coverImage: require('@/assets/images/ibbil/veterinarian/landing.png'),
      landingDetailsImage: require('@/assets/images/ibbil/veterinarian/about.png')
      // features: [
      //   {
      //     img: require('@/assets/images/ibbil/veterinarian/doctor.svg'),
      //     title: 'كشف عيادة',
      //     desc: ''
      //   },
      //   {
      //     img: require('@/assets/images/ibbil/veterinarian/home.svg'),
      //     title: 'زيارة منزلية',
      //     desc: ''
      //   },
      //   {
      //     img: require('@/assets/images/ibbil/veterinarian/phone.svg'),
      //     title: 'مكالمة طبيب',
      //     desc: ''
      //   }
      // ]
      // items: [
      //   {
      //     title: 'كيفية الحجز',
      //     desc: 'لضمان راحة إبلك، توفر لك منصة إبل إمكانية إختيار طريقة الحجز التى تفضلها سواء كنت تفضل كشف عيادة،أو زيارة منزلية، أو استشارة طبية، وتمكنك أيضا من صرف الأدوية من خلال منصة بيطرة على موقع إبل. احجز الآن.',
      //     hasBtn: true,
      //     reverse: true,
      //     text: 'أحجز الأن'
      //   }
      // ]
    }
  },
  computed: {
    features () {
      return [{
        img: require('@/assets/images/ibbil/veterinarian/doctor.svg'),
        title: this.$t('veterinary.clinicDetection'),
        desc: ''
      },
      {
        img: require('@/assets/images/ibbil/veterinarian/home.svg'),
        title: this.$t('veterinary.HomeVisit'),
        desc: ''
      }
      //  {
      //  img: require('@/assets/images/ibbil/veterinarian/phone.svg'),
      //  title: this.$t('veterinary.medicalConsultation'),
      //  desc: ''
      //  }
      ]
    },
    items () {
      return [{
        title: this.$t('veterinary.howToBook'),
        desc: this.$t('veterinary.howToBookDesc'),
        hasBtn: true,
        reverse: true,
        routeName: 'registerVeterinarian',
        text: this.$t('veterinary.bookNow')
      }]
    }
  }
}
</script>
